import React from "react"
import "./related.scss";
import { Link } from "gatsby"


const Related = ({ articleId, articles }) => {

    let count = 0;
    let myList  = [ ];
    
    if(myList.length == 0)
    {
        // Function to generate a random number between min and max (inclusive)
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        // Generate 4 random indices and push corresponding elements to the tempArray
        while (myList.length < 4) {
            const randomIndex = getRandomNumber(0, articles.length - 1);
            if (!myList.includes(articles[randomIndex])) {
                myList.push(articles[randomIndex]);
            }
        }
    }

    return (
        <>
            {
                myList.slice(0, 4).map((item, index) => {

                    return (

                        articleId !== item.node.strapiId && count < 3 ?
                            // console.log(articleId, item.node.strapiId, "test")

                            <li key={index}>
                                <p className="count">{count++}</p>
                                <Link to={`/${item.node.blog_category?.CategorySlug}/${item.node.slug}`}>{item.node.Title}</Link>
                            </li>

                            : ""

                    )
                })
            }

        </>
    )

}
export default Related