import React from "react"
import "./reviews.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const Reviews = ({ text, starImage, reviewerLogo, ratingValue, ratingCount, reviewCount, enableRichSnippet, ratingDisclaimer }) => {

    let reviewsScript = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "@type": "Product",
        "name": "Car Insurance",
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": ratingValue,
            "ratingCount": ratingCount,
            "reviewCount": reviewCount
        }

    }

    return (
        <>

            <div className="reviews-wrapper ">
                <div className="reviews-container">

                    {enableRichSnippet ? <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(reviewsScript) }} /> : ""}

                    {
                        (starImage && !reviewerLogo) ?
                            <div className="display-block">
                                <GatsbyImage image={starImage} alt="review stars" className="stars-image"></GatsbyImage>
                                <div className="reviews-text">{text}</div>
                            </div>
                            :
                            (reviewerLogo && !starImage) ?
                                <div className="display-block">
                                    <GatsbyImage image={reviewerLogo} alt="revier logo" className="review-image"></GatsbyImage>
                                    <div className="reviews-text">{text}</div>
                                </div>

                                :
                                <div><GatsbyImage image={starImage} alt="review stars" className="stars-image"></GatsbyImage>
                                    <div className="reviews-text">{text}</div>
                                    <GatsbyImage image={reviewerLogo} alt="revier logo" className="review-image"></GatsbyImage>
                                </div>
                    }


                </div>
                {ratingDisclaimer ? <div className="reviews-container small-disclaimer">{ratingDisclaimer}</div> : ''}
            </div>
        </>
    )

}

export default Reviews